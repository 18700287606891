import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2560.000000 2560.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,2560.000000) scale(0.100000,-0.100000)" >
					<path d="M0 12800 l0 -12800 12800 0 12800 0 0 12800 0 12800 -12800 0 -12800
0 0 -12800z m13275 10360 c464 -21 827 -59 1240 -130 505 -86 898 -180 1330
-316 116 -36 226 -71 245 -76 154 -44 766 -286 1040 -410 91 -42 527 -258 590
-294 36 -19 94 -52 130 -71 520 -285 1097 -680 1580 -1082 418 -349 946 -868
1245 -1226 28 -33 64 -76 81 -95 97 -108 342 -426 514 -665 223 -312 511 -778
685 -1110 235 -447 439 -906 580 -1300 25 -71 52 -146 60 -165 13 -31 85 -254
150 -460 22 -71 72 -252 129 -475 62 -238 138 -624 195 -995 24 -153 72 -624
87 -850 21 -329 21 -952 -1 -1285 -30 -466 -114 -1100 -190 -1441 -14 -61 -36
-162 -49 -225 -69 -324 -267 -989 -371 -1249 -8 -19 -32 -84 -54 -145 -46
-127 -134 -346 -156 -390 -8 -16 -30 -66 -49 -110 -45 -107 -100 -224 -207
-440 -153 -310 -261 -503 -499 -890 -56 -91 -270 -411 -312 -467 -18 -24 -42
-57 -53 -72 -40 -56 -238 -321 -244 -326 -4 -3 -22 -25 -41 -50 -19 -25 -42
-54 -52 -65 -302 -366 -419 -496 -728 -806 -306 -307 -476 -461 -835 -757 -11
-10 -40 -33 -65 -52 -25 -19 -47 -37 -50 -41 -3 -3 -50 -39 -105 -80 -55 -41
-102 -76 -105 -79 -3 -4 -27 -22 -55 -41 -27 -18 -52 -36 -55 -39 -12 -12
-157 -113 -295 -206 -444 -299 -906 -559 -1408 -792 -108 -51 -199 -92 -202
-92 -2 0 -28 -11 -57 -24 -191 -85 -675 -266 -958 -357 -1230 -395 -2548 -555
-3860 -469 -292 19 -700 65 -940 106 -80 14 -183 31 -230 39 -163 28 -561 116
-720 160 -47 13 -146 40 -220 60 -198 53 -476 142 -680 217 -405 149 -576 218
-855 345 -117 54 -657 327 -723 367 -32 18 -91 53 -132 76 -138 80 -354 213
-510 316 -141 92 -438 300 -450 314 -3 3 -21 17 -40 30 -19 13 -37 27 -40 30
-3 3 -32 25 -65 50 -63 47 -233 183 -325 259 -30 25 -66 55 -80 67 -504 419
-1140 1088 -1530 1609 -25 33 -47 62 -50 65 -34 33 -350 485 -427 610 -22 36
-72 115 -110 175 -259 409 -572 1027 -772 1522 -140 347 -305 824 -384 1113
-49 180 -113 427 -126 485 -6 28 -26 122 -45 210 -63 288 -127 703 -166 1070
-27 255 -42 532 -47 906 -15 1052 102 1955 387 2979 105 375 319 973 481 1338
112 253 279 595 379 777 15 28 44 82 65 120 189 348 537 876 812 1235 43 56
252 318 264 330 8 9 39 45 69 80 30 36 57 67 60 70 3 3 34 39 70 80 36 41 72
82 80 90 8 8 37 40 64 70 164 183 565 576 762 747 12 10 52 46 90 79 115 103
343 289 494 402 52 39 100 75 105 80 80 65 552 389 682 468 61 36 298 177 353
209 298 175 784 413 1145 562 1158 478 2295 735 3496 792 241 12 743 12 984 1z"/>
<path d="M12545 23024 c-446 -14 -970 -65 -1460 -144 -330 -53 -849 -173
-1160 -268 -658 -201 -1106 -378 -1710 -674 -356 -175 -835 -458 -1220 -722
-117 -80 -538 -391 -568 -420 -7 -6 -32 -27 -57 -46 -25 -19 -47 -37 -50 -40
-3 -3 -45 -39 -95 -80 -354 -292 -805 -736 -1131 -1110 -21 -25 -41 -47 -44
-50 -5 -4 -136 -162 -167 -200 -672 -824 -1209 -1743 -1597 -2730 -32 -80 -64
-161 -71 -180 -66 -164 -239 -705 -280 -870 -7 -30 -27 -107 -44 -170 -66
-251 -141 -615 -186 -905 -14 -88 -29 -189 -35 -225 -5 -36 -14 -105 -20 -155
-5 -49 -14 -126 -20 -170 -10 -84 -21 -225 -41 -515 -16 -230 -16 -865 0
-1105 29 -434 78 -869 130 -1160 5 -27 14 -79 21 -115 36 -211 122 -586 203
-890 196 -733 553 -1604 927 -2260 26 -47 57 -101 67 -120 77 -139 303 -497
421 -670 159 -231 411 -571 452 -610 3 -3 21 -25 40 -50 19 -25 37 -47 40 -50
3 -3 39 -45 80 -95 281 -340 639 -709 1010 -1044 141 -127 435 -374 550 -461
47 -35 90 -68 95 -73 35 -29 252 -187 310 -226 39 -26 97 -66 130 -89 163
-111 543 -346 685 -425 702 -387 1293 -644 1975 -859 199 -63 537 -158 670
-187 44 -10 143 -33 220 -50 906 -207 1990 -285 2925 -211 263 21 477 41 540
51 36 5 155 23 265 39 305 46 410 66 790 154 349 80 903 245 1181 351 759 291
1362 587 1948 958 465 294 910 628 1327 998 251 222 595 566 829 829 47 52 87
97 90 100 3 3 30 34 60 70 30 36 58 67 61 70 19 17 342 429 430 550 144 196
415 601 473 706 9 16 21 36 26 44 137 216 439 792 559 1065 24 55 55 125 69
155 71 160 201 491 251 640 12 36 26 74 31 85 35 78 193 613 250 845 17 69 35
141 40 160 11 41 49 225 90 435 183 941 225 1998 120 2970 -85 787 -234 1465
-487 2215 -67 201 -84 244 -202 540 -67 168 -122 295 -207 480 -57 125 -242
489 -309 610 -32 58 -66 119 -74 135 -23 42 -224 378 -234 390 -5 6 -46 69
-92 140 -46 72 -89 137 -95 145 -6 8 -39 56 -73 105 -125 178 -141 201 -216
300 -73 97 -181 235 -191 245 -3 3 -21 25 -40 50 -19 25 -37 47 -40 50 -3 3
-50 59 -105 125 -437 526 -1051 1110 -1610 1531 -912 688 -1835 1175 -2950
1560 -114 39 -472 148 -554 169 -31 8 -144 36 -251 64 -107 27 -290 68 -405
91 -287 56 -292 57 -445 80 -247 37 -264 39 -365 50 -55 6 -134 15 -175 20
-77 10 -236 22 -490 40 -157 10 -654 19 -815 14z m6185 -6135 c0 -66 66 -218
121 -278 l30 -33 -41 -35 c-28 -25 -40 -42 -38 -57 5 -36 40 -30 90 14 l47 41
39 -58 c21 -32 42 -63 45 -70 4 -7 -6 -20 -25 -32 -133 -83 -207 -225 -208
-398 l0 -63 -79 0 -78 0 -7 87 c-13 168 -62 281 -164 373 l-65 58 21 29 c11
15 29 37 41 47 21 19 21 19 70 -28 54 -50 71 -55 96 -30 15 15 14 18 -1 43 -9
14 -27 37 -40 52 -13 14 -24 28 -24 32 0 5 18 32 40 61 22 29 40 58 40 64 0 7
3 12 8 12 9 0 49 104 58 153 7 39 24 51 24 16z m-353 -584 c80 -41 148 -173
160 -311 l6 -74 -30 0 c-29 0 -31 3 -38 47 -9 61 -29 100 -72 140 -65 60 -151
65 -180 9 -18 -32 -23 -120 -10 -168 11 -40 -8 -35 -41 11 -42 57 -59 146 -42
219 17 77 31 101 80 136 30 21 117 17 167 -9z m810 -10 c88 -52 112 -165 59
-277 -23 -47 -93 -134 -102 -125 -1 1 4 29 11 62 26 110 -1 175 -72 175 -77 0
-144 -74 -158 -173 -7 -47 -8 -48 -39 -45 -30 3 -31 4 -28 48 3 40 11 75 38
170 12 40 70 126 100 147 69 48 129 53 191 18z m-2031 -429 c-6 -51 13 -130
47 -190 14 -25 23 -47 21 -49 -3 -3 -35 15 -72 40 -134 88 -300 152 -365 139
-46 -9 -28 15 33 47 47 24 63 28 110 24 30 -3 73 -11 95 -19 l40 -14 37 39
c44 47 61 41 54 -17z m144 29 c16 -8 35 -24 44 -36 l15 -21 30 21 c64 46 159
64 196 38 24 -18 15 -81 -22 -154 l-26 -52 31 -26 c34 -28 55 -85 46 -122 -8
-31 -14 -29 -49 20 -42 57 -84 68 -158 40 l-54 -21 -12 33 c-8 24 -22 38 -46
46 l-34 13 20 27 c30 42 21 135 -17 180 -28 32 -11 38 36 14z m1548 -11 c82
-5 92 -8 92 -25 0 -18 -9 -19 -127 -21 l-128 -2 145 -6 c80 -4 157 -8 173 -9
25 -1 27 -4 27 -41 0 -39 -1 -40 -35 -40 -25 0 -35 -4 -35 -16 0 -13 -32 -15
-272 -12 -235 3 -273 5 -273 18 0 11 -13 16 -47 18 l-48 3 0 45 0 44 78 1 c48
1 66 4 48 8 -17 3 -29 9 -28 14 1 4 2 14 2 22 0 13 22 15 168 10 92 -3 209 -8
260 -11z m-974 -16 c14 -6 34 -28 43 -49 13 -28 24 -39 41 -39 13 0 33 -5 45
-12 82 -43 49 -138 -48 -138 -38 0 -47 -4 -56 -24 -22 -47 -108 -62 -147 -26
-30 28 -28 71 4 109 24 29 24 32 10 53 -24 34 -20 85 9 113 26 27 60 32 99 13z
m2040 -8 c22 -5 53 -18 69 -30 l29 -22 40 22 c30 17 47 20 79 15 l41 -7 -41
-39 c-23 -22 -41 -47 -42 -57 0 -9 -2 -26 -4 -36 -3 -16 9 -52 33 -98 2 -5 -5
-8 -16 -8 -30 0 -72 -38 -72 -66 0 -29 3 -29 -45 -4 -69 35 -145 22 -207 -35
-37 -34 -49 -32 -41 8 9 47 29 82 58 101 26 17 26 17 7 44 -10 15 -24 42 -30
61 -16 46 -16 148 1 154 20 9 99 7 141 -3z m340 -33 c12 -12 27 -31 33 -41 9
-16 15 -16 49 -7 55 15 117 14 193 -5 116 -29 156 -64 73 -64 -99 0 -352 -90
-398 -141 -45 -49 -64 2 -28 74 20 39 26 66 27 128 2 87 11 96 51 56z m-665
-16 c35 -32 41 -81 16 -115 -13 -17 -11 -22 15 -48 38 -37 38 -59 1 -96 -31
-31 -78 -46 -108 -35 -23 9 -53 42 -53 60 0 9 -15 13 -50 13 -41 0 -56 5 -75
25 -14 13 -25 31 -25 40 0 32 30 85 54 97 25 11 31 10 87 -20 4 -1 9 12 13 30
8 40 43 78 72 78 12 0 36 -13 53 -29z m-1065 -144 c-3 -28 -65 -125 -112 -174
-23 -24 -68 -56 -98 -70 -95 -43 -208 -24 -311 53 -17 13 -34 24 -37 24 -3 0
-14 -21 -26 -47 -31 -70 -66 -111 -118 -137 -58 -29 -105 -36 -160 -26 -23 4
-44 5 -47 2 -2 -3 11 -27 30 -54 39 -54 43 -68 18 -68 -10 0 -40 28 -67 63
-27 34 -69 78 -94 97 -25 19 -50 40 -56 45 -20 19 -133 103 -154 114 -11 6
-31 11 -44 11 -46 0 -64 46 -31 82 27 30 62 23 78 -15 14 -35 102 -102 216
-166 121 -67 235 -80 307 -34 45 30 95 111 106 176 4 20 12 37 18 37 6 0 28
-16 49 -35 52 -47 130 -85 189 -92 93 -11 205 69 267 190 21 42 28 48 51 45
19 -2 27 -8 26 -21z m219 13 c22 0 27 -4 27 -26 0 -35 37 -119 77 -176 41 -59
22 -65 -37 -12 l-40 36 0 -33 c0 -37 -31 -96 -55 -105 -9 -4 -15 -19 -15 -35
0 -24 -4 -29 -25 -29 -20 0 -25 5 -25 26 0 16 -12 37 -31 55 -30 28 -31 33
-26 79 6 48 -3 65 -20 36 -13 -21 -75 -56 -102 -56 -23 0 -20 5 31 54 55 54
98 125 98 162 0 32 11 37 65 30 28 -3 62 -6 78 -6z m176 -55 c87 -202 277
-243 462 -100 l57 43 10 -66 c14 -94 34 -136 83 -171 35 -26 51 -31 99 -31
116 0 316 97 411 201 40 42 71 49 101 22 46 -42 2 -112 -63 -99 -23 4 -39 0
-63 -16 -46 -32 -151 -123 -234 -202 -81 -79 -95 -89 -119 -84 -14 3 -15 5 -2
18 52 54 98 114 94 120 -3 5 -20 4 -38 -1 -88 -25 -185 -4 -243 55 -33 33 -44
53 -54 99 -11 57 -12 58 -33 43 -51 -36 -172 -76 -232 -76 -50 0 -128 27 -174
60 -54 40 -151 191 -151 236 0 2 15 4 33 4 29 0 34 -5 56 -55z m-124 -395
c347 -28 712 -86 900 -142 54 -16 65 -26 65 -59 0 -38 -36 -48 -86 -24 -104
49 -338 97 -600 122 -224 22 -848 25 -1054 5 -161 -15 -302 -43 -365 -72 -37
-17 -50 -19 -61 -9 -18 15 -18 53 1 69 36 30 297 84 500 104 164 17 530 19
700 6z m-103 -145 c25 -32 75 -58 153 -80 33 -10 64 -21 68 -25 4 -4 -23 -10
-61 -13 -76 -6 -135 -27 -169 -59 l-22 -20 -28 20 c-42 29 -126 60 -189 68
-30 3 -56 9 -59 13 -2 4 24 13 58 20 113 23 182 51 205 83 17 23 20 23 44 -7z
m-907 -75 c11 -17 68 -43 108 -49 46 -7 49 -23 6 -35 -18 -6 -50 -27 -72 -48
l-41 -38 -25 24 c-14 13 -43 30 -63 37 -21 7 -38 15 -38 19 0 3 13 14 29 24
16 11 36 32 46 48 16 28 39 36 50 18z m1833 -80 c13 -27 27 -50 32 -50 23 0 8
-28 -17 -35 -16 -3 -45 -18 -66 -31 -22 -14 -40 -24 -40 -22 -26 53 -38 66
-80 87 -26 13 -45 26 -43 29 3 3 29 9 57 13 29 5 68 19 88 33 20 14 38 25 41
25 3 1 15 -21 28 -49z m-698 -105 c118 -8 244 -20 280 -25 36 -6 96 -15 134
-20 120 -17 336 -75 336 -91 0 -19 -25 -18 -107 5 -178 48 -470 94 -678 104
-378 20 -910 -10 -1138 -63 -40 -9 -65 -11 -71 -5 -17 17 5 28 84 44 140 28
244 39 500 51 354 17 415 17 660 0z m-5385 -396 c57 -15 101 -62 146 -154 21
-43 39 -83 39 -88 0 -5 16 -36 36 -69 19 -34 38 -74 41 -89 3 -16 12 -34 19
-40 8 -6 14 -18 14 -26 0 -8 26 -69 59 -136 32 -67 78 -162 101 -212 51 -109
128 -271 180 -380 21 -44 53 -111 70 -150 48 -105 59 -129 69 -145 18 -32 51
-111 51 -123 0 -7 9 -18 20 -25 11 -7 20 -19 20 -27 0 -8 4 -23 9 -33 16 -29
58 -126 65 -149 4 -13 11 -23 15 -23 4 0 13 -17 19 -38 7 -21 20 -46 31 -56
18 -17 19 -15 25 71 4 48 8 132 11 186 3 54 10 108 16 120 14 28 82 118 111
150 13 13 53 59 90 103 36 43 78 93 93 109 14 17 61 73 103 125 42 52 81 100
87 105 6 6 31 35 55 65 55 69 112 135 154 180 19 19 53 60 76 90 23 30 65 81
94 112 28 31 78 91 111 133 33 42 74 90 91 108 17 18 35 39 40 48 37 67 215
249 242 249 39 0 71 -19 88 -51 15 -29 15 -44 4 -133 -6 -56 -18 -180 -26
-276 -7 -96 -18 -236 -24 -310 -6 -74 -15 -166 -21 -205 -5 -38 -16 -142 -23
-230 -8 -88 -19 -205 -24 -260 -6 -55 -14 -149 -17 -210 -4 -60 -11 -137 -16
-170 -5 -33 -12 -96 -15 -140 -9 -142 -24 -318 -34 -400 -20 -163 -31 -274
-40 -415 -6 -80 -17 -203 -25 -275 -8 -71 -20 -184 -25 -250 -6 -66 -15 -165
-20 -220 -5 -55 -14 -154 -19 -220 -15 -172 -27 -237 -48 -253 -14 -10 -148
-12 -672 -12 -731 1 -695 -2 -729 72 -130 277 -199 421 -308 642 -41 82 -74
155 -74 162 0 8 -4 14 -10 14 -5 0 -10 8 -10 19 0 10 -7 24 -15 31 -8 7 -15
17 -15 23 0 11 -82 186 -101 217 -5 8 -14 26 -20 40 -16 39 -108 232 -124 260
-15 27 -45 89 -77 160 -11 25 -29 62 -39 82 -11 20 -19 43 -19 51 0 8 -7 20
-15 27 -15 12 -65 111 -65 128 0 5 -6 15 -14 21 -8 7 -17 27 -20 46 -8 38 -11
41 -30 22 -9 -9 -17 -76 -25 -212 -6 -110 -16 -234 -21 -275 -6 -41 -17 -187
-25 -325 -24 -416 -43 -673 -55 -749 -6 -39 -14 -149 -18 -245 -7 -157 -10
-175 -28 -190 -19 -15 -82 -16 -663 -16 -559 0 -643 2 -652 15 -6 9 -8 47 -4
95 3 44 12 166 20 270 8 105 18 231 24 280 5 50 19 200 31 335 11 135 28 290
36 345 8 55 19 178 24 273 5 95 11 176 14 180 2 4 9 73 15 152 16 210 19 239
31 305 5 33 10 81 10 106 0 74 10 103 47 145 54 61 117 135 162 189 22 28 56
66 74 85 19 19 68 76 110 125 42 50 99 117 128 150 28 33 65 77 83 99 17 21
48 57 70 80 21 22 59 66 85 96 25 30 95 111 155 180 61 69 120 136 132 150 12
14 44 50 70 80 27 31 87 102 134 158 117 142 170 169 275 141z m-3113 -25 c12
-7 129 -164 135 -182 4 -9 23 -37 43 -62 20 -25 60 -79 88 -120 29 -41 89
-129 135 -195 45 -66 99 -142 120 -169 20 -27 37 -53 37 -57 0 -5 7 -17 15
-26 9 -10 36 -46 60 -80 25 -35 66 -91 91 -125 26 -35 49 -70 52 -78 3 -8 20
-33 39 -56 18 -23 33 -46 33 -53 0 -6 10 -21 23 -33 12 -13 33 -39 47 -59 180
-265 186 -275 169 -331 -5 -18 -9 -65 -9 -103 0 -39 -6 -124 -14 -190 -8 -66
-17 -169 -21 -230 -3 -60 -12 -157 -20 -215 -7 -58 -16 -168 -20 -244 -3 -77
-10 -160 -16 -185 -5 -25 -14 -103 -19 -173 -5 -70 -13 -136 -19 -145 -5 -10
-12 -76 -16 -148 -3 -71 -13 -188 -20 -260 -8 -71 -19 -200 -25 -285 -8 -123
-14 -160 -29 -180 l-18 -25 -589 -2 c-324 -1 -626 0 -672 4 -73 5 -85 9 -91
27 -9 25 4 264 19 356 6 36 15 133 20 215 10 169 35 427 51 525 5 36 14 128
19 205 5 77 13 169 19 205 6 36 15 141 20 234 6 93 15 204 20 245 6 42 15 136
21 210 6 74 15 153 20 175 5 23 14 104 20 181 20 267 30 380 39 475 5 52 17
187 25 300 9 113 21 243 27 290 6 47 13 128 16 180 7 110 36 185 75 193 23 4
86 -2 100 -9z m8463 -5 c39 -5 120 -14 180 -18 61 -5 121 -13 135 -20 14 -6
57 -15 95 -20 39 -5 84 -14 100 -19 17 -6 59 -18 95 -27 122 -29 143 -36 164
-50 11 -8 29 -15 39 -15 20 0 139 -48 147 -60 3 -4 22 -13 43 -19 20 -7 37
-16 37 -21 0 -4 13 -10 29 -13 16 -3 36 -13 44 -22 9 -8 19 -15 24 -15 14 0
196 -122 251 -169 56 -47 68 -87 36 -113 -9 -7 -33 -31 -53 -53 -20 -22 -75
-80 -123 -130 -125 -129 -200 -210 -303 -322 -118 -129 -192 -201 -237 -230
-34 -23 -45 -25 -130 -21 -91 3 -94 4 -163 49 -127 82 -235 125 -347 139 -123
14 -396 6 -448 -14 -25 -10 -76 -29 -115 -44 -38 -14 -77 -32 -86 -39 -8 -7
-20 -13 -25 -13 -6 0 -24 -10 -41 -22 -17 -13 -41 -30 -53 -38 -12 -8 -35 -27
-51 -42 -16 -16 -33 -28 -39 -28 -5 0 -10 -5 -10 -11 0 -6 -22 -35 -49 -65
-48 -53 -57 -67 -115 -182 -47 -94 -54 -115 -61 -162 -4 -25 -13 -58 -21 -74
-20 -41 -20 -395 0 -434 7 -15 16 -51 20 -81 4 -32 22 -81 42 -120 35 -64 144
-208 170 -224 7 -4 19 -13 26 -21 8 -7 24 -17 36 -20 12 -4 22 -11 22 -16 0
-8 55 -36 145 -76 17 -7 50 -16 75 -20 25 -3 57 -13 73 -21 22 -11 73 -13 255
-10 199 3 234 6 282 24 51 19 56 23 62 59 23 118 29 166 26 210 l-3 49 -330 3
c-245 2 -331 6 -337 15 -9 14 -1 141 13 212 6 28 17 129 24 225 22 269 35 362
56 389 11 13 19 29 19 35 0 19 71 79 120 103 114 54 93 53 959 53 506 1 807
-2 814 -9 15 -12 5 -275 -12 -336 -6 -22 -16 -110 -21 -195 -6 -85 -14 -180
-19 -210 -5 -30 -12 -107 -16 -170 -4 -63 -11 -137 -16 -164 -5 -27 -16 -131
-24 -231 -8 -100 -19 -213 -24 -251 -5 -38 -12 -114 -16 -169 -4 -55 -13 -138
-21 -185 -8 -47 -14 -107 -14 -135 0 -70 -9 -94 -36 -101 -13 -3 -28 -12 -35
-20 -6 -8 -19 -14 -29 -14 -9 0 -28 -8 -41 -18 -13 -11 -37 -22 -54 -26 -16
-4 -45 -13 -62 -21 -98 -44 -205 -85 -223 -85 -9 0 -31 -8 -50 -19 -19 -10
-59 -21 -89 -25 -30 -4 -67 -14 -82 -22 -15 -7 -40 -14 -54 -14 -14 0 -36 -7
-49 -15 -12 -8 -51 -18 -87 -21 -35 -4 -76 -13 -91 -20 -16 -8 -59 -14 -105
-14 -43 0 -105 -7 -138 -16 -92 -24 -604 -32 -697 -10 -37 8 -111 18 -165 21
-54 4 -116 12 -138 20 -22 7 -64 16 -94 20 -29 3 -60 13 -68 21 -8 7 -30 14
-49 14 -19 0 -51 9 -71 20 -21 11 -50 20 -65 20 -16 0 -45 9 -65 20 -21 11
-44 20 -51 20 -8 0 -26 6 -40 14 -15 8 -74 38 -132 66 -143 69 -207 106 -257
146 -23 19 -44 34 -47 34 -3 0 -14 8 -25 18 -10 9 -47 42 -82 72 -106 93 -249
255 -286 325 -9 17 -19 32 -23 35 -5 3 -20 27 -34 54 -14 27 -30 54 -35 60 -5
6 -12 23 -16 38 -4 15 -13 32 -21 39 -8 6 -14 19 -14 28 0 9 -9 33 -20 54 -11
20 -20 50 -20 65 0 16 -9 37 -20 47 -13 12 -20 31 -20 54 0 20 -7 46 -15 58
-8 11 -18 54 -21 94 -4 40 -13 84 -21 98 -10 20 -13 101 -13 356 0 255 3 336
13 356 8 14 17 57 20 96 4 39 14 87 22 106 8 20 15 46 15 57 0 11 9 38 20 60
11 22 20 50 20 63 0 13 7 32 15 43 8 10 15 25 15 33 0 14 16 51 70 161 72 148
125 239 147 253 7 4 13 13 13 19 0 6 7 18 15 27 8 9 36 42 61 75 26 32 65 77
87 100 21 22 46 51 54 64 9 12 19 22 23 22 4 0 33 25 65 55 31 30 73 67 93 82
20 16 39 30 42 33 3 3 25 18 50 35 25 16 62 43 84 59 37 29 102 64 308 167 20
11 44 19 52 19 9 0 35 9 58 19 24 11 77 29 118 41 41 11 95 28 120 36 25 8 54
14 65 14 11 0 45 7 75 15 30 8 107 20 170 25 63 6 129 14 145 19 41 13 374 13
460 0z m-11955 -114 c10 -12 9 -55 -4 -207 -32 -386 -55 -635 -65 -708 -5 -41
-13 -109 -16 -150 -4 -41 -13 -118 -21 -170 -8 -52 -14 -115 -14 -139 l0 -44
25 27 c14 15 25 30 25 34 0 4 15 24 34 44 19 21 55 63 80 95 25 32 51 64 57
71 5 7 46 57 90 112 44 56 87 108 95 118 9 9 35 42 58 72 43 57 85 106 124
147 12 13 22 27 22 31 0 6 30 46 63 82 7 8 26 33 42 55 16 22 45 58 65 81 19
22 49 57 65 78 17 22 37 46 45 55 9 9 26 33 39 54 12 20 31 42 42 47 10 6 19
17 19 25 0 7 9 17 20 20 11 3 20 13 20 20 0 8 6 18 14 22 7 4 18 18 25 30 6
13 26 30 44 39 18 9 35 21 38 25 3 5 32 17 65 27 54 15 126 17 829 17 l770 0
3 -31 c3 -27 -7 -41 -70 -106 -40 -41 -95 -101 -123 -134 -27 -32 -81 -93
-120 -135 -38 -41 -104 -113 -145 -159 -41 -45 -105 -115 -142 -154 -36 -39
-90 -98 -119 -131 -28 -33 -83 -91 -120 -128 -38 -38 -69 -72 -69 -75 -1 -4
-14 -18 -30 -32 -17 -13 -30 -28 -30 -32 0 -5 -19 -28 -42 -52 -24 -24 -77
-80 -118 -126 -41 -46 -102 -111 -135 -145 -33 -34 -82 -88 -110 -119 -27 -32
-92 -103 -145 -158 -52 -55 -99 -108 -104 -119 -5 -10 -13 -19 -18 -19 -16 0
-8 -39 15 -72 12 -18 49 -69 82 -113 56 -74 85 -115 110 -153 6 -9 15 -21 20
-28 6 -7 21 -26 35 -44 14 -18 35 -45 48 -60 12 -15 22 -31 22 -34 0 -4 5 -12
10 -19 13 -15 117 -153 185 -245 28 -37 53 -68 58 -70 4 -2 7 -8 7 -14 0 -6
17 -32 38 -59 20 -27 62 -83 92 -125 30 -42 63 -85 73 -95 9 -10 17 -22 17
-25 0 -3 18 -27 40 -53 22 -26 40 -50 40 -54 0 -4 8 -16 18 -26 9 -11 25 -30
34 -43 10 -13 27 -35 38 -50 11 -14 20 -30 20 -35 0 -5 8 -16 18 -25 32 -31
102 -122 102 -134 0 -5 56 -79 134 -179 89 -113 166 -223 166 -237 0 -10 -9
-22 -19 -28 -31 -16 -1627 -14 -1641 3 -10 11 -141 207 -165 246 -5 9 -26 41
-45 71 -19 30 -39 63 -45 72 -14 23 -73 111 -83 123 -4 6 -23 36 -41 68 -18
31 -36 57 -41 57 -4 0 -10 8 -13 18 -7 19 -37 68 -84 136 -18 26 -33 51 -33
57 0 5 -4 9 -9 9 -5 0 -15 12 -22 28 -17 33 -129 202 -146 220 -7 7 -13 19
-13 26 0 8 -7 20 -16 27 -9 7 -20 22 -25 34 -10 23 -29 52 -76 118 -18 25 -33
49 -34 54 0 4 -16 28 -34 53 -19 25 -45 62 -57 82 -13 21 -26 38 -29 38 -19 0
-33 -72 -40 -205 -4 -82 -13 -165 -18 -184 -6 -19 -13 -89 -16 -155 -3 -67
-13 -161 -21 -211 -8 -49 -18 -155 -23 -235 -5 -80 -14 -158 -20 -173 -6 -16
-11 -63 -11 -105 0 -49 -6 -86 -15 -102 -8 -14 -19 -34 -24 -45 -15 -30 -78
-95 -93 -95 -7 0 -30 -9 -51 -20 -36 -19 -56 -20 -604 -20 -319 0 -574 4 -582
9 -11 7 -14 33 -12 121 1 61 7 134 12 163 6 29 14 104 20 167 5 63 14 142 19
175 6 33 17 143 25 245 8 102 19 201 25 220 5 19 10 71 10 115 0 44 5 109 11
145 5 36 16 133 23 215 34 377 48 517 61 610 8 55 17 150 20 210 4 61 13 148
20 195 8 47 17 137 20 200 4 63 11 138 17 165 5 28 13 122 18 210 5 88 14 176
20 195 5 20 14 94 19 165 5 72 14 143 20 158 6 16 11 59 11 95 0 45 5 75 16
90 l15 22 653 0 c565 0 655 -2 666 -15z m12799 -4479 c65 -14 186 -70 230
-106 l23 -20 -55 -68 c-30 -37 -68 -82 -84 -101 l-30 -34 -47 32 c-124 85
-248 79 -323 -16 -83 -104 -61 -272 45 -345 39 -27 55 -32 106 -32 84 -1 126
18 126 55 0 29 -1 29 -60 29 l-60 0 0 105 0 105 200 0 200 0 -2 -217 -3 -216
-34 -19 c-92 -51 -266 -98 -367 -98 -95 0 -166 14 -240 49 -129 60 -210 154
-253 295 -80 260 81 536 352 602 69 17 197 16 276 0z m-13579 -121 l0 -115
-220 0 -220 0 0 -80 0 -80 203 -2 202 -3 3 -112 3 -113 -206 0 -205 0 0 -150
0 -150 -150 0 -150 0 0 460 0 460 370 0 370 0 0 -115z m382 -62 l3 -178 142
-3 143 -3 2 178 3 178 150 0 150 0 3 -457 2 -458 -155 0 -155 0 -2 168 -3 167
-140 0 -140 0 -3 -167 -2 -168 -150 0 -150 0 0 453 c0 250 3 457 7 460 3 4 71
7 149 7 l144 0 2 -177z m1523 162 c206 -43 336 -174 367 -367 40 -261 -105
-467 -368 -523 -49 -10 -136 -15 -301 -15 l-233 0 0 460 0 460 233 0 c165 0
252 -5 302 -15z m1072 -42 c14 -32 42 -101 63 -153 21 -52 66 -162 100 -245
34 -82 83 -204 110 -270 27 -66 56 -137 64 -157 l16 -38 -163 0 -163 0 -21 63
-21 62 -175 3 -175 2 -23 -65 -23 -65 -153 0 c-95 0 -153 4 -153 10 0 5 20 55
45 110 25 55 45 103 45 106 0 3 11 29 24 57 13 29 77 178 142 332 64 154 121
286 127 293 7 9 51 12 159 12 l149 0 26 -57z m1063 -63 l0 -119 -127 -3 -128
-3 -3 -337 -2 -338 -150 0 -150 0 -2 338 -3 337 -131 3 -132 3 -1 110 c-1 60
1 114 4 119 4 6 166 10 416 10 l409 0 0 -120z m627 55 c14 -33 49 -118 78
-190 29 -71 85 -209 125 -305 98 -239 140 -343 140 -352 0 -5 -72 -8 -159 -8
l-159 0 -22 65 -22 65 -175 -2 -175 -3 -23 -60 -22 -60 -156 -3 -156 -3 45
108 c26 60 63 149 84 198 40 95 90 212 185 435 31 74 61 145 66 158 9 22 10
22 165 20 l156 -3 25 -60z m1259 42 c96 -26 194 -70 194 -89 0 -17 -89 -196
-101 -203 -8 -6 -64 15 -120 45 -37 19 -191 51 -219 45 -25 -5 -30 -12 -30
-36 0 -37 21 -50 135 -84 261 -78 334 -142 335 -293 0 -107 -44 -180 -140
-231 -119 -64 -274 -74 -446 -31 -85 21 -242 93 -250 115 -5 11 90 215 100
215 2 0 41 -20 87 -44 84 -45 210 -86 261 -86 15 0 39 5 53 12 21 9 25 18 23
42 -4 38 -32 53 -173 90 -189 50 -264 104 -292 209 -38 148 55 285 227 333 79
22 254 17 356 -9z m564 -264 c0 -268 1 -290 20 -320 55 -90 184 -93 223 -5 8
18 14 120 17 317 l5 290 150 0 150 0 3 -260 c3 -294 -4 -363 -48 -451 -36 -74
-91 -126 -174 -165 -229 -106 -513 -25 -612 177 l-29 59 -3 323 -3 322 150 0
151 0 0 -287z m1329 257 c117 -42 194 -145 175 -233 -14 -66 -68 -137 -116
-153 -28 -8 -22 -17 28 -43 86 -46 127 -141 110 -255 -10 -66 -86 -142 -176
-175 -63 -24 -76 -25 -372 -29 l-308 -4 0 454 c0 250 3 458 8 462 4 4 138 6
297 3 271 -4 294 -5 354 -27z m1270 1 c53 -22 136 -72 180 -111 23 -20 22 -29
-9 -60 -12 -12 -88 -98 -113 -129 l-28 -34 -37 35 c-50 47 -83 66 -134 77
-133 30 -236 -71 -226 -222 7 -116 79 -186 191 -187 48 0 119 29 180 74 l29
21 84 -88 c46 -49 84 -92 84 -95 -1 -13 -105 -90 -155 -114 -163 -81 -320 -86
-470 -13 -165 79 -255 226 -256 416 -1 142 46 248 147 338 57 50 84 65 174 98
58 21 77 23 185 20 92 -4 132 -10 174 -26z m896 5 c161 -68 238 -143 291 -283
24 -61 26 -78 21 -173 -3 -84 -9 -116 -29 -160 -74 -163 -231 -269 -423 -285
-197 -17 -403 80 -490 230 -59 100 -79 238 -50 348 41 158 149 270 309 324 63
21 85 23 201 20 93 -3 141 -9 170 -21z m782 -113 c109 -229 133 -282 133 -290
0 -4 8 -18 18 -31 l18 -23 51 108 c115 242 163 346 163 353 0 16 36 20 181 20
l149 0 0 -460 0 -460 -130 0 -130 0 0 240 c0 153 -4 240 -10 240 -5 0 -10 -4
-10 -9 0 -5 -16 -45 -37 -88 -20 -43 -57 -125 -82 -183 l-47 -105 -100 -3 -99
-3 -27 58 c-30 64 -80 174 -124 273 l-29 65 -5 -240 -5 -240 -132 -3 -133 -3
0 454 c0 250 3 457 7 460 3 4 76 7 160 7 l154 0 66 -137z m1459 114 c10 -12
53 -67 94 -122 167 -223 248 -324 254 -318 3 4 6 109 6 235 l0 228 135 0 135
0 0 -460 0 -460 -127 0 c-128 1 -128 1 -147 28 -60 84 -331 422 -338 422 -4 0
-8 -101 -8 -225 l0 -225 -135 0 -135 0 0 460 0 460 123 0 c116 0 125 -1 143
-23z m-3940 -657 c51 -25 68 -61 62 -130 -16 -166 -258 -152 -258 14 0 57 27
101 74 122 43 19 73 17 122 -6z m3505 5 c61 -32 87 -121 55 -184 -23 -43 -64
-65 -124 -65 -49 -1 -56 2 -87 36 -44 48 -55 100 -29 147 38 71 119 100 185
66z"/>
<path d="M18528 15843 c28 -2 76 -2 105 0 28 2 5 3 -53 3 -58 0 -81 -1 -52 -3z"/>
<path d="M7824 9555 c-3 -6 -3 -107 -2 -225 l3 -215 57 -3 c152 -8 238 75 238
230 0 86 -42 167 -104 200 -29 16 -187 26 -192 13z"/>
<path d="M8892 9362 c-23 -64 -42 -123 -42 -129 0 -10 25 -13 90 -13 59 0 90
4 90 11 0 16 -84 249 -90 249 -3 0 -24 -53 -48 -118z"/>
<path d="M10615 9468 c-2 -7 -22 -62 -44 -122 -22 -60 -38 -113 -35 -118 7
-11 171 -10 178 1 3 5 -12 57 -34 116 -22 59 -40 114 -40 121 0 17 -19 18 -25
2z"/>
<path d="M13617 9563 c-2 -5 -2 -14 -1 -20 1 -7 2 -33 3 -58 l1 -45 83 0 c103
0 127 13 127 70 0 29 -5 42 -19 50 -22 11 -188 14 -194 3z"/>
<path d="M13617 9233 c-2 -5 -2 -14 -1 -20 1 -7 2 -33 3 -58 l1 -45 95 0 c88
0 98 2 120 25 32 31 32 58 -1 84 -23 18 -41 21 -120 21 -51 0 -95 -3 -97 -7z"/>
<path d="M15900 9548 c-90 -26 -143 -102 -143 -208 -1 -106 54 -186 145 -211
100 -26 194 29 228 135 18 55 8 156 -20 201 -43 70 -133 106 -210 83z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
